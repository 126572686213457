import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-sacramento-california.png'
import image0 from "../../images/cities/scale-model-of-chinatown-mall-in-sacramento-california.png"
import image1 from "../../images/cities/scale-model-of-about-capa-in-sacramento-california.png"
import image2 from "../../images/cities/scale-model-of-sacramento-rivertrain-in-sacramento-california.png"
import image3 from "../../images/cities/scale-model-of-old-sacramento-historic-district-in-sacramento-california.png"
import image4 from "../../images/cities/scale-model-of-pony-express-statue-in-sacramento-california.png"
import image5 from "../../images/cities/scale-model-of-leland-stanford-mansion-state-historic-park-in-sacramento-california.png"
import image6 from "../../images/cities/scale-model-of-california-state-railroad-museum-in-sacramento-california.png"
import image7 from "../../images/cities/scale-model-of-crocker-art-museum-in-sacramento-california.png"
import image8 from "../../images/cities/scale-model-of-cathedral-of-the-blessed-sacrament-in-sacramento-california.png"
import image9 from "../../images/cities/scale-model-of-sacramento-history-museum-in-sacramento-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Sacramento'
            state='California'
            image={image}
            lat='38.5815719'
            lon='-121.49439960000001'
            attractions={ [{"name": "Chinatown Mall", "vicinity": "400 J St, Sacramento", "types": ["point_of_interest", "establishment"], "lat": 38.5824596, "lng": -121.50140670000002}, {"name": "about capa", "vicinity": "1127 11th St, Sacramento", "types": ["point_of_interest", "establishment"], "lat": 38.578009, "lng": -121.49272430000002}, {"name": "Sacramento RiverTrain", "vicinity": "400 N Harbor Blvd, West Sacramento", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 38.5971482, "lng": -121.5496091}, {"name": "Old Sacramento Historic District", "vicinity": "1014 2nd St #200, Sacramento", "types": ["point_of_interest", "establishment"], "lat": 38.5828124, "lng": -121.50521860000003}, {"name": "Pony Express Statue", "vicinity": "2nd St & J St, Sacramento", "types": ["point_of_interest", "establishment"], "lat": 38.5832156, "lng": -121.5038993}, {"name": "Leland Stanford Mansion State Historic Park", "vicinity": "800 N St, Sacramento", "types": ["museum", "park", "point_of_interest", "local_government_office", "establishment"], "lat": 38.5761903, "lng": -121.4978549}, {"name": "California State Railroad Museum", "vicinity": "125 I St, Sacramento", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.5850387, "lng": -121.5043518}, {"name": "Crocker Art Museum", "vicinity": "216 O St, Sacramento", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.577039, "lng": -121.50648760000001}, {"name": "Cathedral of the Blessed Sacrament", "vicinity": "1017 11th St, Sacramento", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 38.5789419, "lng": -121.49187289999998}, {"name": "Sacramento History Museum", "vicinity": "101 I St, Sacramento", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.5848232, "lng": -121.50498979999998}] }
            attractionImages={ {"Chinatown Mall":image0,"about capa":image1,"Sacramento RiverTrain":image2,"Old Sacramento Historic District":image3,"Pony Express Statue":image4,"Leland Stanford Mansion State Historic Park":image5,"California State Railroad Museum":image6,"Crocker Art Museum":image7,"Cathedral of the Blessed Sacrament":image8,"Sacramento History Museum":image9,} }
       />);
  }
}